import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicerService {
  private readonly URL = environment.setting.servicerApiUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * パラメータをbodyに変換.
   *
   * @param parameter パラメータ
   * @return body
   */
   parameterToBody<T>(parameter: T): string {
    let body = '';
    let amp = '';
    Object.keys(parameter).map((key) => {
      body += amp + key + '=' + parameter[key];
      amp = '&';
    })
    return body;
  }

  /**
   * URL取得.
   *
   * @param path パス
   * @returns URL
   */
  private getUrl(path: string): string {
    return this.URL + path;
  }

  /**
   * post実行.
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
   post<T, V>(path: string, parameter: T): Observable<V> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.post<V>(this.getUrl(path), parameter, options);
  }

    /**
   * URL取得(RVRH).
   *
   * @param path パス
   * @returns URL
   */
    private getUrlRvrh(path: string): string {
      let rvrhUrl = this.URL;
      const idx = rvrhUrl.lastIndexOf('/vehicle');
      if (idx > 0) {
        rvrhUrl =  rvrhUrl.substring(0, idx) + '/reserve';
      }
      return rvrhUrl + path;
    }

    /**
   * post実行(RVRH).
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
    postRvrh<T, V>(path: string, parameter: T): Observable<V> {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      return this.http.post<V>(this.getUrlRvrh(path), parameter, options);
    }
}
