import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { TelemetryDriverTablet } from './models/telemetry-driver-tablet';
import { MqttPublishService } from './mqtt-publish.service';
import { MqttService } from './mqtt.service';

import { SharedSettingService } from '../shared-setting.service';

@Injectable({
  providedIn: 'root'
})
export class TelemetryDriverTabletMqttService {
  private publishClient: MqttService;

  private topic: string;

  constructor(
    private mqttPublishService: MqttPublishService,
    private sharedSettingService: SharedSettingService
  ) { }

  /**
   * 開始します.
   */
  async start() {
    this.topic = this.sharedSettingService.getVehicle().vin + environment.setting.telemetryDriverTabletTopic;
    this.publishClient = this.mqttPublishService.getMqttClient();
  }

  /**
   * 開始します(VIN指定).
   */
  async startByVin(vin: string) {
    this.topic = vin + environment.setting.telemetryDriverTabletTopic;
    this.publishClient = this.mqttPublishService.getMqttClient();
  }

  /**
   * 停止します.
   */
  async stop() {
    this.publishClient = null;
  }

  /**
   * トピックを取得します.
   */
  getTopic(): string {
    return this.topic;
  }

  /**
   * コネクションの状態を確認し、接続が切れている場合、再接続します.
   * 
   * @returns 接続状態
   */
  checkAndRetryConnect(): boolean {
    return true;
  }

  /**
   * メッセージを送信します.
   * 
   * @param message 送信メッセージ
   * @returns 送信結果
   */
  publishMessage(message: TelemetryDriverTablet): boolean {
    if (this.publishClient?.checkConnect()) {
      this.publishClient.sendTopic(JSON.stringify(message), this.topic);

      return true;
    }

    return false;
  }
}
